import "./front.less";
import FrontPage from "ui/front/page/FrontPage";
import {removeDeprecatedLsKeys} from "ui/helpers/cleaners";
import Form from "ui/elements/form/Form";
import List from "ui/elements/list/List";
import PopupMenu from "ui/elements/popup-menu/PopupMenu";
import {escapeHtml, formatDateTime} from "ui/helpers/formatters";
import {detectUserLanguage, getCountryNameByCode} from "ui/i18n";
import {CurrentUser} from "ui/CurrentUser";
import {IS_SMALL_VIEW, IS_WEBP_SUPPORTED, STATIC_URL} from "ui/config";
import ByteArrayReader from "ui/helpers/blob/ByteArrayReader";
import ChangesImageLoader from "ui/front/changes/ChangesImageLoader";
import ChangesLoader from "ui/front/changes/ChangesLoader";
import MatrixLoader from "ui/front/matrix/MatrixLoader";
import DragController from "ui/helpers/DragController";
import Utils from "ui/helpers/Utils";

/**
 * @type {{
 *  detectUserLanguage: (function(): string),
 *  getCountryNameByCode: (function(string): string),
 *  IS_SMALL_VIEW: boolean,
 *  IS_WEBP_SUPPORTED: boolean,
 *  controller: FrontPage,
 *  escapeHtml: function,
 *  CurrentUser: CurrentUser,
 *  Form: Form,
 *  formatDateTime: (function(*=): string),
 *  List: List,
 *  ByteArrayReader: ByteArrayReader,
 *  MatrixLoader: MatrixLoader,
 *  ChangesLoader: ChangesLoader,
 *  ChangesImageLoader: ChangesImageLoader,
 *  PopupMenu: PopupMenu,
 *  DragController: DragController,
 *  Utils: Utils
 *  }}
 */
globalThis.floor796 = {
	Form,
	List,
	PopupMenu,
	escapeHtml,
	formatDateTime,
	detectUserLanguage,
	getCountryNameByCode,
	CurrentUser,
	ByteArrayReader,
	MatrixLoader,
	ChangesLoader,
	ChangesImageLoader,
	DragController,
	Utils,
	IS_SMALL_VIEW,
	IS_WEBP_SUPPORTED,
	STATIC_URL,
	controller: null,
};

document.addEventListener('DOMContentLoaded', async () => {
	removeDeprecatedLsKeys();
	globalThis.floor796.controller = new FrontPage();

	window.Telegram?.WebApp?.disableVerticalSwipes();
});
