import {CLIENT_MAX_INACTIVE_TIME, IS_F796_RENDER_ENGINE} from "ui/config";
import CanvasMatrixView from "ui/front/matrix/CanvasMatrixView";
import {detectUserLanguage, LANG_EN} from "ui/i18n";
import CanvasViewSlot from "ui/front/matrix/CanvasViewSlot";
import {packString, packUint16, packUint32} from "ui/helpers/formatters";
import {WS_MSG_TYPE} from "ui/front/websocket/enum";
import {CurrentUser} from "ui/CurrentUser";

const SAVE_POSITION_TIMEOUT = 1000;
const ENV_UPDATE_TIMEOUT = 10000;

const TYPE_CODES = {
	CLICKED_ITEMS: 1,
	CLICKED_SOURCES: 2,
	CLICKED_TARGETS: 3,
	VIEWED_ITEMS: 4,
	DURATION: 5,
	FPS: 6,
	DOWNLOAD_SPEED_AVG: 7,
	DOWNLOAD_SPEED_MAX: 8,
	LANG: 9,
	PAGE_WIDTH: 10,
	PAGE_HEIGHT: 11,
	RENDER_ENGINE: 12,
	DPR: 13,
	REFERRER: 14,
	UI_VERSION: 15,
};

export default class StatClient {
	constructor(socket) {
		/**
		 * @type {SocketClient}
		 * @private
		 */
		this._socket = socket;

		/**
		 * @type {Set<number>}
		 * @private
		 */
		this._clickedItems = new Set();

		/**
		 * @type {number[]}
		 * @private
		 */
		this._unsavedClickedItems = [];

		/**
		 * @type {Set<number>}
		 * @private
		 */
		this._clickedItemSources = new Set();

		/**
		 * @type {number[]}
		 * @private
		 */
		this._unsavedClickedItemSources = [];

		/**
		 * @type {number}
		 * @private
		 */
		this._clickedTargets = 0;

		/**
		 * @type {number}
		 * @private
		 */
		this._autoSendTimerId = 0;

		/**
		 * @type {number}
		 * @private
		 */
		this._updateDurationTimerId = 0;

		/**
		 * @type {number}
		 * @private
		 */
		this._duration = 0;

		/**
		 * @type {Set<number>}
		 * @private
		 */
		this._seenItemIds = new Set();

		/**
		 * @type {number[]}
		 * @private
		 */
		this._unsavedSeenItemIds = [];

		/**
		 * @type {ChangesMap|null}
		 */
		this._changesMap = null;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._isGeneralStatSent = false;

		/**
		 * @type {number}
		 * @private
		 */
		this._lastActivityTime = Date.now();

		this._autoSendHandlerHandler = this._makeAutoSend.bind(this);
		this._updateDurationHandler = this._updateDuration.bind(this);
	}

	init() {
		this._makeAutoSend();
		this._updateDuration();

		const pointerTouchHandler = this._handlePointerTouchEvent.bind(this);
		document.addEventListener('mousedown', pointerTouchHandler, {capture: true});
		document.addEventListener('touchstart', pointerTouchHandler, {capture: true});

		document.addEventListener('visibilitychange', this._autoSendHandlerHandler);
		window.addEventListener('beforeunload', () => {
			this._makeAutoSend();
			this._saveViewedItems();
		});
	}

	send() {
		if (!this._socket.isConnected()) {
			return;
		}

		const isGuest = !CurrentUser.get().isModerator();
		const downloadSpeed = CanvasViewSlot.getDownloadTime();
		const out = [WS_MSG_TYPE.CLIENT_STAT];

		if (!this._isGeneralStatSent) {
			this._isGeneralStatSent = true;
			out.push(
				TYPE_CODES.LANG, detectUserLanguage() === LANG_EN ? 1 : 2,
				TYPE_CODES.RENDER_ENGINE, IS_F796_RENDER_ENGINE ? 2 : 1,
				TYPE_CODES.PAGE_WIDTH, ...packUint16(window.innerWidth || document.documentElement.clientWidth),
				TYPE_CODES.PAGE_HEIGHT, ...packUint16(window.innerHeight || document.documentElement.clientHeight),
				TYPE_CODES.DPR, Math.min(255, Math.round(window.devicePixelRatio * 10)),
				TYPE_CODES.REFERRER, ...packString(document.referrer.substring(0, 100)),
				TYPE_CODES.UI_VERSION, 1,
			);
		}

		if (this._unsavedClickedItems.length > 0) {
			if (isGuest) {
				out.push(
					TYPE_CODES.CLICKED_ITEMS,
					...packUint16(this._unsavedClickedItems.length),
					...this._unsavedClickedItems.map(id => packUint16(id)).flat()
				);
			}
			this._unsavedClickedItems = [];
		}

		if (this._unsavedClickedItemSources.length > 0) {
			if (isGuest) {
				out.push(
					TYPE_CODES.CLICKED_SOURCES,
					...packUint16(this._unsavedClickedItemSources.length),
					...this._unsavedClickedItemSources.map(id => packUint16(id)).flat()
				);
			}
			this._unsavedClickedItemSources = [];
		}

		if (this._clickedTargets > 0) {
			if (isGuest) {
				out.push(TYPE_CODES.CLICKED_TARGETS, ...packUint32(this._clickedTargets));
			}
		}

		if (this._unsavedSeenItemIds.length > 0) {
			if (isGuest) {
				out.push(
					TYPE_CODES.VIEWED_ITEMS,
					...packUint16(this._unsavedSeenItemIds.length),
					...this._unsavedSeenItemIds.map(id => packUint16(id)).flat()
				);
			}
			this._unsavedSeenItemIds = [];
		}

		if (isGuest) {
			out.push(
				TYPE_CODES.DURATION, ...packUint32(this._duration),
			);
		}

		if (downloadSpeed.max > 50) {
			out.push(
				TYPE_CODES.DOWNLOAD_SPEED_AVG, ...packUint32(downloadSpeed.avg),
				TYPE_CODES.DOWNLOAD_SPEED_MAX, ...packUint32(downloadSpeed.max),
			);

		}

		const fps = CanvasMatrixView.getAvgFps();
		if (fps > 0) {
			out.push(
				TYPE_CODES.FPS, Math.round(fps),
			);
		}

		// reset duration. It is necessary, because user can open multiple tabs with project and we need to count
		// only delta duration from each active tab
		this._duration = 0;

		this._socket.sendMessage(new Uint8Array(out));
	}

	/**
	 * @param {number} targetId
	 */
	saveClickByTarget(targetId) {
		if ((this._clickedTargets & targetId) === targetId) {
			return;
		}

		this._clickedTargets |= targetId;
	}

	/**
	 * @param {number} itemId
	 */
	saveClickByItem(itemId) {
		if (this._clickedItems.has(itemId)) {
			return;
		}

		this._clickedItems.add(itemId);
		this._unsavedClickedItems.push(itemId);
	}

	/**
	 * @param {number} itemId
	 */
	saveClickByItemSource(itemId) {
		if (this._clickedItemSources.has(itemId)) {
			return;
		}

		this._clickedItemSources.add(itemId);
		this._unsavedClickedItemSources.push(itemId);
	}

	/**
	 * @param {MatrixViewPosition} pos
	 * @param {ChangesMap} changesMap
	 */
	watchChangesInViewport(pos, changesMap) {
		let timerId = 0;

		this._changesMap = changesMap;

		pos.addObserver(() => {
			clearTimeout(timerId);
			timerId = setTimeout(this._saveViewedItems.bind(this), SAVE_POSITION_TIMEOUT);
		});
	}

	/**
	 * @private
	 */
	_saveViewedItems() {
		const items = this._changesMap.getSeenItems();
		const newList = [...items].map(item => item.id)
			.filter(id => id > 0 && !this._seenItemIds.has(id));

		if (newList.length > 0) {
			newList.forEach(id => this._seenItemIds.add(id));
			this._unsavedSeenItemIds.push(...newList);
		}

		return [];
	}

	/**
	 * @private
	 */
	_makeAutoSend() {
		if (this._autoSendTimerId > 0) {
			clearTimeout(this._autoSendTimerId);
		}

		this._autoSendTimerId = setTimeout(this._autoSendHandlerHandler, ENV_UPDATE_TIMEOUT);

		if (this._lastActivityTime < Date.now() - CLIENT_MAX_INACTIVE_TIME) {
			return;
		}

		if (!document.hidden) {
			this.send();
		}
	}

	/**
	 * @private
	 */
	_updateDuration() {
		if (this._updateDurationTimerId > 0) {
			clearTimeout(this._updateDurationTimerId);
		}

		this._updateDurationTimerId = setTimeout(this._updateDurationHandler, 1000);

		if (!document.hidden) {
			this._duration++;
		}
	}

	/**
	 * @private
	 */
	_handlePointerTouchEvent() {
		this._lastActivityTime = Date.now()
	}
}
